/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import myBanglesProject from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

const useStyles = makeStyles(myBanglesProject);

export default function MyBanglesProject({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            {/* Project 2 START */}
            <div className={classes.projects}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >
                            <Muted>
                                <h6>OUR WORK</h6>
                            </Muted>
                            <h2 className={classes.title}>
                                Some of Our Awesome Products - 2
                            </h2>
                            <h5 className={classes.description}>
                            Quality is what we focus on, Come if you want something better.
                            </h5>
                            <div className={classes.sectionSpace} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card plain className={classes.card2}>
                                <a
                                    href="https://www.creative-tim.com/product/material-kit-react?ref=mkpr-projects-section-sections"
                                    target="_blank"
                                >
                                    <CardHeader image plain>
                                        <img
                                            src={
                                                "https://s3.amazonaws.com/creativetim_bucket/products/83/original/opt_mk_react_thumbnail.jpg?1525851474"
                                            }
                                            alt="..."
                                        />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage:
                                                    "url('https://s3.amazonaws.com/creativetim_bucket/products/83/original/opt_mk_react_thumbnail.jpg?1525851474')",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                </a>
                                <CardBody plain>
                                    <a
                                        href="https://www.creative-tim.com/product/material-kit-react?ref=mkpr-projects-section-sections"
                                        target="_blank"
                                    >
                                        <h4 className={classes.cardTitle}>
                                            Material Kit Free React
                                        </h4>
                                    </a>
                                    <h6 className={classes.description}>FREE UI KIT</h6>
                                    <p
                                        className={classes.description + " " + classes.marginTop20}
                                    >
                                       We Make Bangles At The Lowest Making Charges Rate. We Provide Additional Benefits To Clients; We Will Print The Brand Name of The Client On The Boxes For Free Of Cost if The Minimum Quantity Of The Order Is 4000 Boxes Or Above.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card plain className={classes.card2}>
                                <a
                                    href="https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                    target="_blank"
                                >
                                    <CardHeader image plain>
                                        <img
                                            src="https://s3.amazonaws.com/creativetim_bucket/products/66/thumb/opt_lbp_react_thumbnail.jpg?1509466309"
                                            alt="..."
                                        />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage:
                                                    "url('https://s3.amazonaws.com/creativetim_bucket/products/66/thumb/opt_lbp_react_thumbnail.jpg?1509466309')",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                </a>
                                <CardBody plain>
                                    <a
                                        href="https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                        target="_blank"
                                    >
                                        <h4 className={classes.cardTitle}>
                                            Light Bootstrap Dashboard PRO React
                                        </h4>
                                    </a>
                                    <h6 className={classes.description}>Premium Template</h6>
                                    <p
                                        className={classes.description + " " + classes.marginTop20}
                                    >
                                        Light Bootstrap Dashboard PRO is a Bootstrap 4 Admin Theme
                                        designed to look simple and beautiful. Forget about boring
                                        dashboards and grab yourself a copy to kickstart new
                                        project!
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card plain className={classes.card2}>
                                <a
                                    href="https://www.creative-tim.com/product/now-ui-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                    target="_blank"
                                >
                                    <CardHeader image plain>
                                        <img
                                            src="https://s3.amazonaws.com/creativetim_bucket/products/73/original/opt_nudp_react_thumbnail.jpg?1518533306"
                                            alt="..."
                                        />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage:
                                                    "url('https://s3.amazonaws.com/creativetim_bucket/products/73/original/opt_nudp_react_thumbnail.jpg?1518533306')",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                </a>
                                <CardBody plain>
                                    <a
                                        href="https://www.creative-tim.com/product/now-ui-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                        target="_blank"
                                    >
                                        <h4 className={classes.cardTitle}>
                                            Now UI Dashboard PRO React
                                        </h4>
                                    </a>
                                    <h6 className={classes.description}>Premium Template</h6>
                                    <p
                                        className={classes.description + " " + classes.marginTop20}
                                    >
                                        Now UI Dashboard React is an admin dashboard template
                                        designed by Invision and coded by Creative Tim. It is built
                                        on top of Reactstrap, using Now UI Dashboard and it is fully
                                        responsive.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            {/* Project 2 END */}
        </div>
    );
}
