import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import MyLogin from "views/MyBangles/Section/MyLogin";
import MySignUp from "views/MyBangles/Section/MySignUp";
import MyContact from "views/MyBangles/Section/MyContact";
import MySizeMMList from "views/MyBangles/MySizeMMList";
import MyProductPage from "views/MyBangles/MyProductPage";
import MySizeGuide from "views/MyBangles/Section/MySizeGuide";
import MyProductList from "views/MyBangles/MyProductList";
import MyProductListOffline from "views/MyBangles/MyProductListOffline";
import MyMakingCharge from "views/MyBangles/MyMakingCharge";
import AddAddress from "views/MyBangles/Profile/AddAddress";
import UpdateAddress from "views/MyBangles/Profile/UpdateAddress";
import EditProfile from "views/MyBangles/Profile/EditProfile";
import GeneratePassword from "views/MyBangles/Profile/GeneratePassword";
import Cart from "views/MyBangles/Cart/Cart";
import AddCart from "views/MyBangles/Cart/AddCart";
import CartShippingAddress from "views/MyBangles/Cart/CartShippingAddress";
import CartView from "views/MyBangles/Cart/CartView";
import MyOrder from "views/MyBangles/Cart/MyOrder";
import OrderView from "views/MyBangles/Cart/OrderView";
import OrderViewPrint from "views/MyBangles/Cart/OrderViewPrint";
import PaymentRecieved from "views/MyBangles/Cart/PaymentRecieved";
import MyBanglesPage from "views/MyBangles/MyBanglesPage.js";
import CareerField from "views/MyBangles/Section/CareerField";
import PrivacyPolicy from "views/MyBangles/Section/PrivacyPolicy";
import ReturnPolicy from "views/MyBangles/Section/ReturnPolicy";
import ReturnAmount from "views/MyBangles/Section/ReturnAmount";
import RefundPolicy from "views/MyBangles/Section/RefundPolicy";
import TermCondition from "views/MyBangles/Section/Term&Condition";
import License from "views/MyBangles/Section/License";
import ProductStock from "views/MyBangles/ProductStock"
import OrderStock from "views/MyBangles/OrderStock";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/return" component={ReturnAmount} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/login" component={MyLogin} />
      <Route path="/sign-up" component={MySignUp} />
      <Route path="/contact-us" component={MyContact} />
      <Route path="/bangles-size-mm-list" component={MySizeMMList} />
      <Route path="/bangles-product" component={MyProductPage} />
      <Route path="/bangles-size-guide" component={MySizeGuide} />
      <Route path="/product-list" component={MyProductList} />
      <Route path="/offline-product-list" component={MyProductListOffline} />
      <Route path="/bangles-making" component={MyMakingCharge} />
      <Route path="/add-address" component={AddAddress} />
      <Route path="/edit-update-address" component={UpdateAddress} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/edit-password" component={GeneratePassword} />
      <Route path="/cart" component={Cart} />
      <Route path="/add-cart" component={AddCart} />
      <Route path="/cart-shipping-address" component={CartShippingAddress} />
      <Route path="/cart-view" component={CartView} />
      <Route path="/my-order" component={MyOrder} />
      <Route path="/order-view" component={OrderView} />
      <Route path="/order-view-print" component={OrderViewPrint} />
      <Route path="/payment-recieved" component={PaymentRecieved} />
      <Route path="/careers" component={CareerField} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/return-policy" component={ReturnPolicy} />
      <Route path="/refund-policy" component={RefundPolicy} />
      <Route path="/terms-and-conditions" component={TermCondition} />
      <Route path="/license" component={License} />
      <Route path="/todays-deal" component={ProductStock} />
      <Route path="/order-stock" component={OrderStock} />
      <Route path="/" component={MyBanglesPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
